<script lang="ts" setup>
import type { Banner } from "~/lib/models";

interface Props {
    compact?: boolean;
    banners: Banner[];
}

withDefaults(defineProps<Props>(), {
    compact: true,
});
const selectedIndex = ref<number>(0);

onMounted(async () => {
    selectedIndex.value = 0;
});
</script>

<template>
    <VCarousel v-model="selectedIndex" :slide-count="banners.length">
        <section
            v-for="(banner, idx) in banners"
            :key="idx"
            class="relative overflow-hidden"
            :class="{ flex: idx === selectedIndex, hidden: idx !== selectedIndex }"
        >
            <section class="w-full">
                <picture>
                    <source media="(max-width: 1024px)" :srcset="banner.imgUrl?.sm" />
                    <img
                        :src="banner.imgUrl?.lg"
                        class="object-cover w-full"
                        :class="{ 'h-[21rem] lg:h-[30rem]': compact, 'h-[28rem] lg:h-[45.4rem]': !compact }"
                    />
                </picture>
            </section>

            <section class="absolute top-0 left-0 w-full pt-36">
                <div class="section-content">
                    <div class="w-52 lg:w-[20.4rem]">
                        <h1>
                            {{ banner.title }}
                        </h1>
                        <p class="my-5 text-base text-blue-900 lg:text-[1.6rem] font-medium leading-normal">
                            {{ banner.subtitle }}
                        </p>
                        
                        <NuxtLink :to="banner.redirectToUrl">
                            <VButton color="bg-primary" :compact="true" class="w-fit" :text="banner.buttonText" />
                        </NuxtLink>
                    </div>
                </div>
            </section>
        </section>
    </VCarousel>
</template>

