import { AntboxError, Either, Node, NodeFilter, NodeServiceClient, left, right } from "../deps";
import type { Banner } from "../models";

export class BannerService {
    #BANNER_UUID = "banner";
    #BANNER_IMAGE_UUID = "banner-image";

    constructor(private readonly nodeServiceClient: NodeServiceClient, private readonly tenant: string) { }

    listHome() {
        return this.#list("Marcas");
    }

    listProducts() {
        return this.#list("Productos");
    }

    listReseller() {
        return this.#list("Revendedores");
    }

    async #list(bannerGroup: string): Promise<Either<AntboxError, Banner[]>> {
        const query: NodeFilter[] = [
            ["aspects", "contains", this.#BANNER_UUID],
            [`properties.${this.#BANNER_UUID}:group`, "==", bannerGroup],
        ];
        const banners: Banner[] = [];
        const bannersOrError = await this.nodeServiceClient.query(query);

        if (bannersOrError.isRight()) {
            for await (const n of bannersOrError.value.nodes) {
                banners.push(await this.#convertNodeToBannerModel(n));
            }

            return right(banners);
        }

        return left(bannersOrError.value)
    }

    async #convertNodeToBannerModel(node: Node): Promise<Banner> {
        return {
            buttonText: node.properties?.[`${this.#BANNER_UUID}:buttonText`] as string,
            imgUrl: {
                lg: await this.#getBannerImgUrl(node.uuid, "lg"),
                sm: await this.#getBannerImgUrl(node.uuid, "sm")
            },
            redirectToUrl: node.properties?.[`${this.#BANNER_UUID}:redirectUrl`] as string,
            subtitle: node.properties?.[`${this.#BANNER_UUID}:subtitle`] as string,
            title: node.title,
        };
    }

    async #getBannerImgUrl(bannerUuid: string, screenSize: "lg" | "sm"): Promise<string> {
        const query: NodeFilter[] = [
            ["aspects", "contains", this.#BANNER_IMAGE_UUID],
            [`properties.${this.#BANNER_IMAGE_UUID}:${this.#BANNER_UUID}`, "==", bannerUuid],
            [`properties.${this.#BANNER_IMAGE_UUID}:screenSize`, "==", screenSize],
        ];

        const imgOrErr = await this.nodeServiceClient.query(query)

        if (imgOrErr.isRight()) {
            const url = this.nodeServiceClient.getNodeUrl(imgOrErr.value.nodes[0]?.uuid);
            return `${url}?x-tenant=${this.tenant}`
        }

        return ""
    }
}
